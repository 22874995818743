import React from 'react';


class ThermalTemplate extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      table_name: this.props.table_name,
      qr_img: this.props.qr_img,
    }
  }

  render() {
    return (
      <>
        <div id="testqrdiv" className="qrdiv" style={{
          backgroundImage: `url('/media/qr_template/thermalV2-order.png')`,
          backgroundSize: '100%',
          height: '75mm',
          width: '120mm',
          position: 'relative',
          display:'none'
        }}>
          <div
            style={{
              position: 'absolute',
              top: '32px',
              right: '29px'
            }}>
            <img src={this.state.qr_img} alt="" width={180} height={180} />
          </div>
          <h1
            style={{
              position: 'absolute',
              left: '25px',
              top: '100px',
              fontSize: '50px',
              fontWeight: '900',
              color: '#fff'
            }}>
            {this.state.table_name}
          </h1>
        </div>
      </>
    )
  }
}

export default ThermalTemplate;
