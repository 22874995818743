import React from 'react';


class A6HorizantalTemplate extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      table_name: this.props.table_name,
      qr_img: this.props.qr_img,
      cover: this.props.cover,
      logo: this.props.logo
    }
    console.log(this.state)
  }

  render() {
    return (
      <>
        <div id="testqrdiv" className="qrdiv"
          style={{
            position: 'relative',
            height: '108.5mm',
            width: '153.5mm',
            overflow:'hidden',
            display:'none'
          }}>
          <div style={{
            backgroundImage: `url('/media/qr_template/A6horizantal-order.png')`,
            backgroundSize: '100%',
            height: '108.5mm',
            width: '153.5mm',
            position: 'absolute',
            zIndex: '2'
          }}>
          </div>
          <img src={this.state.logo}
            width={111}
            height={111}
            style={{
              position: 'absolute',
              top: '127px',
              right: '67px',
              zIndex: '2',
              borderRadius: '50%',
              objectFit: 'cover'
            }}
            alt={this.state.logo} />
          <div
            style={{
              position: 'absolute',
            }}>
            <img src={this.state.cover}
              width={'886px'}
              alt={this.state.cover} />
          </div>
          <div
            style={{
              position: 'absolute',
              background: '#fff',
              top: '130px',
              left: '150px',
              zIndex: '3'
            }}>
            <img src={this.state.qr_img} alt="" width={170} height={170} />
          </div>
          <h1
            style={{
              position: 'absolute',
              right: '20px',
              top: '30px',
              fontSize: '60px',
              fontWeight: '900',
              color: '#fff',
              zIndex: '4'
            }}>
            {this.state.table_name}
          </h1>
        </div>
      </>
    )
  }
}

export default A6HorizantalTemplate;
