import React from 'react';


class StickerTemplate extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      table_name: this.props.table_name,
      qr_img: this.props.qr_img,
    }
  }

  render() {
    return (
      <>
        <div id="testqrdiv" className="qrdiv" style={{
          backgroundImage: `url('/media/qr_template/sticker-order.png')`,
          backgroundSize: '100%',
          height: '80mm',
          width: '55mm',
          position: 'relative',
          display:'none'
        }}>
          <div
            style={{
              position: 'absolute',
              top: '67px',
              right: '33px'
            }}>
            <img src={this.state.qr_img} alt="" width={140} height={140} />
          </div>
          <h1
            style={{
              position: 'absolute',
              left: '65px',
              top: '18px',
              fontSize: '25px',
              fontWeight: '900',
              color: '#fff'
            }}>
            {this.state.table_name.substring(0,9)}
          </h1>
        </div>
      </>
    )
  }
}

export default StickerTemplate;
