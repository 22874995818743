import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CropperImage from "../CropperImage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const CreateZone = () => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "113032" ,defaultMessage:"Create Zone" }));
  const classes = useStyles();
  const history = useHistory();

  const [item_name, setItem_name] = useState();
  const [item_name_en, setItem_name_en] = useState();
  const [description, setDescription] = useState();
  const [description_en, setDescription_en] = useState();

  const [state, setState] = useState(true);


  const allDataGroup = [];

  const AlertError = (message) => {
    Swal.fire(intl.formatMessage({ id: "113050" ,defaultMessage:"Menu Management!" }), message, "error");
  };

  useEffect(() => {
  }, []);

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const onSubmit = async () => {
    if (item_name === undefined || item_name === "") {
      return AlertError(intl.formatMessage({ id: "113051" ,defaultMessage:"Please insert item name." }));
    }
    if (item_name_en === undefined || item_name_en === "") {
      return AlertError(intl.formatMessage({ id: "113054" ,defaultMessage:"Please insert item name en." }));
    }

    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");

    var events = {
      shop_id: get_shop_id,
      branch_id: get_branch_id,
      zone_name: item_name,
      zone_name_en: item_name_en,
      zone_desc: description,
      zone_desc_en: description_en,
      enabled: state,
    };

    let apiUrls = `${baseURL}/management/tablezone`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "post",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          intl.formatMessage({ id: "113059" ,defaultMessage:"Create !" }),
          intl.formatMessage({ id: "113060" ,defaultMessage:"Sorry for the inconvenience. Please check your data again." }), + err,
          "error"
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "113032" ,defaultMessage:"Create Zone" })}></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
          
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
            {intl.formatMessage({ id: "113033" ,defaultMessage:"Item name th" })}:{" "}
            </p>
            <input
              name="item_name"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(item_name) => setItem_name(item_name.target.value)}
            />
          </div>
          
          <div className="form-inline" style={{ marginBottom: "25px" }}>
        
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            <span style={{ color: "red" }}>
              *
            </span>
            {intl.formatMessage({ id: "113034" ,defaultMessage:"Item name en" })}:{" "}
            </p>
            <input
              name="item_name_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(item_name_en) =>
                setItem_name_en(item_name_en.target.value)
              }
            />
          </div>
          
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            {intl.formatMessage({ id: "113035" ,defaultMessage:"Description" })}:{" "}
            </p>
            <input
              name="description"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(description) =>
                setDescription(description.target.value)
              }
            />
          </div>
          
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
            {intl.formatMessage({ id: "113036" ,defaultMessage:"Description en" })}:{" "}
            </p>
            <input
              name="description_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(description_en) =>
                setDescription_en(description_en.target.value)
              }
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113037" ,defaultMessage:"Enabled" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/TableZone">
              <button type="button" className="btn btn-secondary wbt mr-5">
              {intl.formatMessage({ id: "113038" ,defaultMessage:"Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
            {intl.formatMessage({ id: "113039" ,defaultMessage:"Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
