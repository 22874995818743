import React from 'react';


class A6Template extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      table_name: this.props.table_name,
      qr_img: this.props.qr_img,
      cover: this.props.cover,
      logo: this.props.logo
    }
  }

  render() {
    return (
      <React.Fragment >
        <div id="testqrdiv" className="qrdiv" style={{
          backgroundImage: `url('/media/qr_template/A5A6-order.png')`,
          backgroundSize: '100%',
          height: '148.5mm',
          width: '105mm',
          position: 'relative',
          display:'none'
        }}>
          <img src={this.state.logo}
            width={80}
            height={80}
            style={{
              position: 'absolute',
              top: '10px',
              left: '8px',
              zIndex: '1',
              borderRadius: '50%',
              objectFit: 'cover'
            }}
            alt={this.state.logo} />
          <img src={this.state.cover}
            style={{
              position: 'relative',
              height: '183.5px',
              top: '97px'
            }}
            alt={this.state.cover} />
          <div
            style={{
              position: 'absolute',
              background: '#fff',
              padding: '20px',
              borderRadius: '10px',
              left: '117px'
            }}>
            <img src={this.state.qr_img} alt="" width={120} height={120} />
          </div>
          <h1
            style={{
              position: 'absolute',
              right: '20px',
              top: '25px',
              fontSize: '50px',
              fontWeight: '900',
              color: '#fff'
            }}>
            {this.state.table_name}
          </h1>
        </div>

      </React.Fragment >
    )
  }
}

export default A6Template;
