import React, { useState, useRef, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";
import "../../component.css";
import CropperImage from "../CropperImage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    noLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    inputColor: {
        backgroundColor: "#F4F6F9",
        borderRadius: "5px",
        borderWidth: "0",
    },
}));

export const EditBranch = () => {
    const intl = useIntl();
    const suhbeader = useSubheader();
    const { authToken } = useSelector((state) => state.auth);
    const classes = useStyles();
    suhbeader.setTitle(intl.formatMessage({ id: "102007", defaultMessage: "Shop/Branch" }));
    const history = useHistory();
    const [name, setname] = useState();
    const [name_en, setname_en] = useState();
    const [description, setdescription] = useState();
    const [description_en, setdescription_en] = useState();
    const [address, setaddress] = useState();
    const [address_en, setaddress_en] = useState();
    const [tel, settel] = useState();
    const [email, setemail] = useState();
    const [is_vat_enabled, setis_vat_enabled] = useState(true);
    const [is_vat_exclude, setis_vat_exclude] = useState(true);
    const [vat_rate, setvat_rate] = useState();
    const [is_service_charge_enabled, setis_service_charge_enabled] = useState(true);
    const [is_service_charge_percentage_enabled, setis_service_charge_percentage_enabled] = useState(true);
    const [service_charge_percentage, setservice_charge_percentage] = useState(0);
    const [service_charge_amount, setservice_charge_amount] = useState(0);
    const [open, setopen] = useState();
    const [close, setclose] = useState();
    const [fullsize, setFullsize] = useState(false);
    const [nameImageItem_image_path, setNameImageItem_image_path] = useState("");
    const [nameImageItem_image_path_qr, setNameImageItem_image_path_qr] = useState("");
    const [item_image_path, setItem_image_path] = useState("");
    const [item_image_path_qr, setItem_image_path_qr] = useState("");

    const [line_liff_id, setline_liff_id] = useState("");
    const [line_account, setline_account] = useState("");
    const [line_messaging_access_token, setline_messaging_access_token] = useState("");
    const [payment_bank_name, setpayment_bank_name] = useState("");
    const [payment_account_name, setpayment_account_name] = useState("");
    const [payment_account_number, setpayment_account_number] = useState("");
    const [line_account_type, setline_account_type] = useState("");
    const [fontWeight, setFontWeight] = useState(1);
    const [defaultLanguge, setDefaultLanguge] = useState("");
    const [taxId, setTaxId] = useState("");

    const [stateItem_image_path, setStateItem_image_path] = useState({
        previewItem_image_path: null,
        rawItem_image_path: null,
        imageURLItem_image_path: null,
    });

    const [stateItem_image_path_qr, setStateItem_image_path_qr] = useState({
        previewItem_image_path: null,
        rawItem_image_path: null,
        imageURLItem_image_path: null,
    });

    const [enableDynamicQR, setEnableDynamicQR] = useState(false);
    const [efeedbackLink, setEfeedbackLink] = useState('');

    const AlertError = (message) => {
        Swal.fire("Edit Branch!", message, "error");
    };

    const handleSend = async () => {
        if (name === undefined || name === "") {
            return AlertError("Please insert name th !!");
        }
        // if (name_en === undefined || name_en === "") {
        //     return AlertError("Please insert name en !!");
        // }
        // if (description === undefined || description === "") {
        //     return AlertError("Please insert description !!");
        // }
        // if (description_en === undefined || description_en === "") {
        //     return AlertError("Please insert description en !!");
        // }
        // if (address === undefined || address === "") {
        //     return AlertError("Please insert address !!");
        // }
        // if (address_en === undefined || address_en === "") {
        //     return AlertError("Please insert address en!!");
        // }
        // if (tel === undefined || tel === '' || isNaN(tel) || Number(tel) === 0) {
        //     return AlertError("Please insert tel !!");
        // }
        // if (Number(tel.length) > 10 || Number(tel.length) < 10) {
        //     return AlertError("Please insert tel must = 10 !!");
        // }
        // if (email === undefined || email === "") {
        //     return AlertError("Please insert email !!");
        // }
        // if (vat_rate === undefined || vat_rate === '' || isNaN(vat_rate) || Number(vat_rate) === 0) {
        //     return AlertError('please insert is vat enabled.')
        // }
        // if (service_charge_percentage === undefined || service_charge_percentage === '' || isNaN(service_charge_percentage) || Number(vat_rate) === 0) {
        //     return AlertError('please insert is service charge percentage.')
        // }
        // if (open === undefined || open === "") {
        //     return AlertError("Please insert open !!");
        // }
        // if (close === undefined || close === "") {
        //     return AlertError("Please insert close !!");
        // }
        let events = {
            name: name,
            name_en: name_en,
            description: description,
            description_en: description_en,
            address: address,
            address_en: address_en,
            tel: tel,
            email: email,
            is_vat_enabled: is_vat_enabled,
            is_vat_exclude: is_vat_exclude,
            vat_rate: vat_rate,
            is_service_charge_enabled: is_service_charge_enabled,
            is_service_charge_percentage_enabled: is_service_charge_percentage_enabled,
            service_charge_percentage: is_service_charge_percentage_enabled ? service_charge_percentage : 0,
            service_charge_amount: is_service_charge_percentage_enabled ? 0 : service_charge_amount,
            open: open,
            close: close,
            url_logo_image: item_image_path ? item_image_path : null,
            line_liff_id: line_liff_id ? line_liff_id : null,
            line_account: line_account ? '~' + line_account : null,
            line_messaging_access_token: line_messaging_access_token ? line_messaging_access_token : null,
            payment_bank_name: payment_bank_name ? payment_bank_name : null,
            payment_account_name: payment_account_name ? payment_account_name : null,
            payment_account_number: payment_account_number ? payment_account_number : null,
            line_account_type: line_account_type ? line_account_type : "personal",
            allow_show_full_image: fullsize,
            menu_text_size: fontWeight,
            qr_image: item_image_path_qr ? item_image_path_qr : null,
            is_enable_dynamic_qr: enableDynamicQR,
            e_feedback_link: efeedbackLink,
            default_language: defaultLanguge,
            tax_id: taxId
        };
        let apiUrls = `${baseURL}/management/branch`;
        axios({
            method: "put",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
            data: events,
        })
            .then(async (res) => {
                Swal.fire("Save !", "Save Branch success", "success");
                history.goBack();
            })
            .catch((err) => {
                Swal.fire(
                    "Save !",
                    "Your can not Branch. !! " + err,
                    "error"
                );
            });
    };


    useEffect(() => {
        getBranch();
    }, []);

    const getBranch = async () => {
        axios({
            method: "get",
            url: `${baseURL}/management/branch`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        }).then((res) => {
            console.log('res :', res.data.data.is_service_charge_percentage_enabled);
            if (res.message) {
                history.push("/logout");
            } else {
                setname(res.data.data.name);
                setname_en(res.data.data.name_en);
                setdescription(res.data.data.description);
                setdescription_en(res.data.data.description_en);
                setaddress(res.data.data.address);
                setaddress_en(res.data.data.address_en);
                settel(res.data.data.tel);
                setemail(res.data.data.email);
                setis_vat_enabled(res.data.data.is_vat_enabled);
                setis_vat_exclude(res.data.data.is_vat_exclude);
                setvat_rate(res.data.data.vat_rate);
                setis_service_charge_enabled(res.data.data.is_service_charge_enabled);
                setis_service_charge_percentage_enabled(res.data.data.is_service_charge_percentage_enabled);
                setservice_charge_percentage(res.data.data.service_charge_percentage);
                setservice_charge_amount(res.data.data.service_charge_amount);
                setopen(res.data.data.open);
                setclose(res.data.data.close);
                setItem_image_path(res.data.data.url_logo_image);
                setline_liff_id(res.data.data.line_liff_id);
                setFullsize(res.data.data.allow_show_full_image ? res.data.data.allow_show_full_image : false);
                try {
                    setline_account(res.data.data.line_account.replaceAll('~', ''));
                } catch {

                }
                setline_messaging_access_token(res.data.data.line_messaging_access_token);
                setpayment_bank_name(res.data.data.payment_bank_name);
                setpayment_account_name(res.data.data.payment_account_name);
                setpayment_account_number(res.data.data.payment_account_number);
                setline_account_type(res.data.data.line_account_type);
                setFontWeight(res.data.data.menu_text_size)
                setItem_image_path_qr(res.data.data.qr_image)

                setEnableDynamicQR(res.data.data.is_enable_dynamic_qr ? res.data.data.is_enable_dynamic_qr : false);
                setEfeedbackLink(res.data.data.e_feedback_link);

                setDefaultLanguge(res.data.data.default_language ? res.data.data.default_language : "TH");
                setTaxId(res.data.data.tax_id);
            }
        });
    };

    const handleChangeSwitchIs_vat_enabled = () => {
        setis_vat_enabled(!is_vat_enabled);
    };

    const handleChangeSwitchIs_vat_exclude = () => {
        setis_vat_exclude(!is_vat_exclude);
    };
    const handleChangeSwitchIs_service_charge_enabled = () => {
        setis_service_charge_enabled(!is_service_charge_enabled);
    };
    const handleChangeSwitchIs_service_charge_percentage_enabled = () => {
        setis_service_charge_percentage_enabled(!is_service_charge_percentage_enabled);
    };
    //img
    const handleChangeItem_image_path = async (e) => {
        if (e.target.files.length) {
            e.preventDefault();
            let file = e.target.files[0];
            e.target.value = '';
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    var height = this.height;
                    var width = this.width;
                    if (height < 512 || width < 512) {
                        Swal.fire(
                            "Upload !",
                            "At least you can upload a 512*512 photo size. !! ",
                            "error"
                        );
                        e.target.value = '';
                        return false;
                    } else {
                        setStateItem_image_path({
                            rawItem_image_path: file,
                            imageURLItem_image_path: e.target.result,
                        });
                        e.target.value = '';
                        return true;
                    }
                };
            };
        }
    };
    const handleImageUploadItem_image_path = async (file) => {
        var formdata = new FormData();
        formdata.append("image", file, URL.createObjectURL(file));
        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };
        fetch(`${baseURL}/upload/`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let res = JSON.parse(result);
                setStateItem_image_path({
                    previewItem_image_path: null,
                    rawItem_image_path: null,
                    imageURLItem_image_path: null,
                });
                setItem_image_path(`${imageURL}/${res.filename}`);
                setNameImageItem_image_path(res.filename);
            })
            .catch((error) => console.log("error", error));
    };
    const removeImageItem_image_path = async () => {
        setStateItem_image_path({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
        setItem_image_path("");
    };
    const handleCancelUploadItem_image_path = () => {
        setStateItem_image_path({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
    };

    //qr
    const handleChangeItem_image_path_qr = async (e) => {
        if (e.target.files.length) {
            e.preventDefault();
            let file = e.target.files[0];
            e.target.value = '';
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    var height = this.height;
                    var width = this.width;
                    if (height < 512 || width < 512) {
                        Swal.fire(
                            "Upload !",
                            "At least you can upload a 512*512 photo size. !! ",
                            "error"
                        );
                        e.target.value = '';
                        return false;
                    } else {
                        setStateItem_image_path_qr({
                            rawItem_image_path: file,
                            imageURLItem_image_path: e.target.result,
                        });
                        e.target.value = '';
                        return true;
                    }
                };
            };
        }
    };
    const handleImageUploadItem_image_path_qr = async (file) => {
        var formdata = new FormData();
        formdata.append("image", file, URL.createObjectURL(file));
        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };
        fetch(`${baseURL}/upload/`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let res = JSON.parse(result);
                setStateItem_image_path_qr({
                    previewItem_image_path: null,
                    rawItem_image_path: null,
                    imageURLItem_image_path: null,
                });
                setItem_image_path_qr(`${imageURL}/${res.filename}`);
                setNameImageItem_image_path_qr(res.filename);
            })
            .catch((error) => console.log("error", error));
    };
    const removeImageItem_image_path_qr = async () => {
        setStateItem_image_path_qr({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
        setItem_image_path("");
    };
    const handleCancelUploadItem_image_path_qr = () => {
        setStateItem_image_path_qr({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "108015", defaultMessage: "edit branch" })}></CardHeader>
            <CardBody>
                <div className="container">
                    <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                        <div className="h6" style={{ paddingTop: "20px" }}>

                            {intl.formatMessage({
                                id: "113418",
                                defaultMessage: "Branch Detail",
                            })}

                        </div>
                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                <span style={{ color: "red" }}>*</span>
                                {intl.formatMessage({ id: "108016", defaultMessage: "Thai name:" })}:{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={name}
                                onChange={(name) => setname(name.target.value)}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108017", defaultMessage: "English name:" })}:{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={name_en}
                                onChange={(name_en) => setname_en(name_en.target.value)}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108018", defaultMessage: "Details:" })}:{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={description}
                                onChange={(description) => setdescription(description.target.value)}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108019", defaultMessage: "English details:" })}:{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={description_en}
                                onChange={(description_en) => setdescription_en(description_en.target.value)}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108020", defaultMessage: "Thai address:" })}:{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={address}
                                onChange={(address) => setaddress(address.target.value)}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108021", defaultMessage: "English address:" })}:{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={address_en}
                                onChange={(address_en) => setaddress_en(address_en.target.value)}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108022", defaultMessage: "Phone number:" })}:{" "}
                            </p>
                            <input
                                type="number"
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={tel}
                                onChange={(tel) => settel(tel.target.value)}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108023", defaultMessage: "Email:" })}:{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={email}
                                onChange={(email) => setemail(email.target.value)}
                            />
                        </div>
                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113394", defaultMessage: "Tax ID" })}:{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                maxLength={13}
                                value={taxId}
                                onChange={(taxId) => setTaxId(taxId.target.value)}
                            />
                        </div>
                    </div>

                    <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                        <div className="h6" style={{ paddingTop: "20px" }}>

                            {intl.formatMessage({
                                id: "113419",
                                defaultMessage: "Setting",
                            })}

                        </div>
                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108024", defaultMessage: "Enable VAT" })}:{" "}
                            </p>
                            <Switch
                                checked={is_vat_enabled}
                                onChange={() => handleChangeSwitchIs_vat_enabled()}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108025", defaultMessage: "Separate VAT calculation (vat Exclude):" })}:{" "}
                            </p>
                            <Switch
                                checked={is_vat_exclude}
                                onChange={() => {
                                    handleChangeSwitchIs_vat_exclude()
                                }}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108026", defaultMessage: "VAT rate:" })}:{" "}
                            </p>
                            <input
                                type="number"

                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={vat_rate}
                                onChange={(vat_rate) => setvat_rate(vat_rate.target.value)}
                            />
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "108027", defaultMessage: "Enable service charges:" })}:{" "}
                            </p>
                            <Switch
                                checked={is_service_charge_enabled}
                                onChange={() => handleChangeSwitchIs_service_charge_enabled()}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </div>

                        {is_service_charge_enabled &&
                            <>
                                <div
                                    className="form-inline"
                                    style={{
                                        marginBottom: "25px",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                        {intl.formatMessage({ id: "113424", defaultMessage: "Enable service charges percentage:" })}:{" "}
                                    </p>
                                    <Switch
                                        checked={is_service_charge_percentage_enabled}
                                        onChange={() => handleChangeSwitchIs_service_charge_percentage_enabled()}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                </div>

                                {is_service_charge_percentage_enabled === true &&
                                    <>
                                        <div
                                            className="form-inline"
                                            style={{
                                                marginBottom: "25px",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    marginTop: "15px",
                                                    float: "left",
                                                    width: "20%",
                                                }}
                                            >
                                                {intl.formatMessage({ id: "108028", defaultMessage: "service fee percentage" })}:{" "}
                                            </p>
                                            <input
                                                type="number"

                                                style={{
                                                    height: "38px",
                                                    width: "530px",
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                }}
                                                value={service_charge_percentage}
                                                onChange={(service_charge_percentage) => setservice_charge_percentage(service_charge_percentage.target.value)}
                                            />
                                        </div>
                                    </>
                                }
                                {is_service_charge_percentage_enabled === false &&
                                    <>
                                        <div
                                            className="form-inline"
                                            style={{
                                                marginBottom: "25px",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    marginTop: "15px",
                                                    float: "left",
                                                    width: "20%",
                                                }}
                                            >
                                                {intl.formatMessage({ id: "113425", defaultMessage: "service fee amount" })}:{" "}
                                            </p>
                                            <input
                                                type="number"

                                                style={{
                                                    height: "38px",
                                                    width: "530px",
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                }}
                                                value={service_charge_amount}
                                                onChange={(service_charge_amount) => setservice_charge_amount(service_charge_amount.target.value)}
                                            />
                                        </div>
                                    </>
                                }

                            </>
                        }

                        <div className="form-inline" style={{ marginBottom: "25px" }}>
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113119", defaultMessage: "enable image fullsize" })}
                            </p>
                            <Switch
                                checked={fullsize}
                                onChange={(e) => setFullsize(e.target.checked)}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </div>

                        <div className="form-inline" style={{ marginBottom: '25px' }}>
                            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
                                {intl.formatMessage({ id: "108029", defaultMessage: "opening time" })} : {' '}
                            </p>
                            <input
                                className="form-control"
                                type="time"
                                value={open}
                                onChange={e => setopen(e.target.value)}
                                id="example-time-input"
                                style={{
                                    height: '38px',
                                    width: '230px',
                                    backgroundColor: '#F4F6F9',
                                    borderRadius: '5px',
                                    borderWidth: '0'
                                }}
                            />
                        </div>

                        <div className="form-inline" style={{ marginBottom: '25px' }}>
                            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
                                {intl.formatMessage({ id: "108030", defaultMessage: "closing time" })} : {' '}
                            </p>

                            <input
                                className="form-control"
                                type="time"
                                value={close}
                                onChange={e => setclose(e.target.value)}
                                id="example-time-input"
                                style={{
                                    height: '38px',
                                    width: '230px',
                                    backgroundColor: '#F4F6F9',
                                    borderRadius: '5px',
                                    borderWidth: '0'
                                }}
                            />
                        </div>


                        <div className="form-inline" style={{ marginBottom: "25px" }}>
                            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                {intl.formatMessage({ id: "104024", defaultMessage: "image" })} : <br></br>
                                (512*512)
                            </p>
                            <div
                                className="d-flex"
                                style={{
                                    width: "530px",
                                }}>
                                <input
                                    name="item_image_path"
                                    style={{
                                        flexGrow: "1",
                                        height: "38px",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                    }}
                                    disabled
                                    value={item_image_path}
                                />

                                <span className="btn btn-primary btn-file wbt pt-4" style={{
                                    whiteSpace: "nowrap",
                                    width: "fit-content"
                                }}>
                                    {intl.formatMessage({ id: "104025", defaultMessage: "Upload/Edit" })}
                                    <input type="file" onChange={handleChangeItem_image_path} />
                                </span>

                                <button
                                    type="button"
                                    className="btn btn-danger wbt"
                                    onClick={removeImageItem_image_path}
                                >
                                    {intl.formatMessage({ id: "104026", defaultMessage: "delete" })}
                                </button>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "50%",
                                marginBottom: "15px"
                            }}
                        >
                            {stateItem_image_path.previewItem_image_path ? (
                                <img
                                    style={{
                                        width: "300px",
                                        height: "300px",
                                    }}
                                    src={stateItem_image_path.previewItem_image_path}
                                />
                            ) : item_image_path ? (
                                <img
                                    style={{
                                        width: "300px",
                                        height: "300px",
                                    }}
                                    src={item_image_path}
                                />
                            ) : null}
                        </div>
                        {stateItem_image_path.imageURLItem_image_path ? (
                            <CropperImage
                                image={stateItem_image_path.imageURLItem_image_path}
                                handleCancel={handleCancelUploadItem_image_path}
                                handleUpload={handleImageUploadItem_image_path}
                                scale={1}
                            />
                        ) : (
                            ""
                        )}



                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113286", defaultMessage: "E-feedback Link" })}{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={efeedbackLink}
                                onChange={(efeedbackLink) => setEfeedbackLink(efeedbackLink.target.value)}
                            />
                        </div>

                        <div className="form-inline" style={{ marginBottom: "25px" }}>
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113120", defaultMessage: "font weight" })}
                            </p>
                            <select
                                value={fontWeight}
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                onChange={(e) => setFontWeight(e.target.value)}
                            >
                                <option value={0}>normal</option>
                                <option value={1}>Bold</option>
                            </select>
                        </div>
                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "999999", defaultMessage: "Web Slash QR Default Language:" })}{" "}
                            </p>
                            <select
                                value={defaultLanguge}
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                onChange={(defaultLanguge) => setDefaultLanguge(defaultLanguge.target.value)}
                            >
                                <option value="TH">Thailand</option>
                                <option value="EN">English</option>
                                <option value="CN">Chinese</option>
                            </select>
                        </div>
                    </div>
                    <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                        <div className="h6" style={{ paddingTop: "20px" }}>

                            {intl.formatMessage({
                                id: "113070",
                                defaultMessage: "Line Account",
                            })}

                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113069", defaultMessage: "Line account type:" })}{" "}
                            </p>
                            <select
                                value={line_account_type}
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                onChange={(line_account_type) => setline_account_type(line_account_type.target.value)}
                            >
                                <option value="personal">Personal</option>
                                <option value="OA">OA</option>
                            </select>
                        </div>

                        {line_account_type === "OA" ? (
                            <div>
                                <div
                                    className="form-inline"
                                    style={{
                                        marginBottom: "25px",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                        {intl.formatMessage({ id: "208010", defaultMessage: "Liff id:" })}{" "}
                                    </p>
                                    <input
                                        style={{
                                            height: "38px",
                                            width: "530px",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        value={line_liff_id}
                                        onChange={(line_liff_id) => setline_liff_id(line_liff_id.target.value)}
                                    />
                                </div>
                                <div
                                    className="form-inline"
                                    style={{
                                        marginBottom: "25px",
                                    }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            float: "left",
                                            width: "20%",
                                        }}
                                    >
                                        {intl.formatMessage({ id: "208010", defaultMessage: "Channel access token:" })}{" "}
                                    </p>
                                    <textarea
                                        style={{
                                            height: "70px",
                                            width: "530px",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        value={line_messaging_access_token}
                                        onChange={(line_messaging_access_token) => setline_messaging_access_token(line_messaging_access_token.target.value)}
                                    />
                                </div>
                            </div>

                        ) : ('')}

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113070", defaultMessage: "Line account:" })}{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={line_account}
                                onChange={(line_account) => setline_account(line_account.target.value)}
                            />
                        </div>
                    </div>

                    <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                        <div className="h6" style={{ paddingTop: "20px" }}>

                            {intl.formatMessage({
                                id: "113420",
                                defaultMessage: "Bank Account Info.",
                            })}

                        </div>
                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113071", defaultMessage: "Bank:" })}{" "}
                            </p>
                            <select
                                value={payment_bank_name}
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                onChange={(payment_bank_name) => setpayment_bank_name(payment_bank_name.target.value)}
                            >
                                <option value="">{intl.formatMessage({ id: "113100", defaultMessage: "เลือก" })}</option>
                                <option value="ธนาคารกรุงเทพ">{intl.formatMessage({ id: "113077", defaultMessage: "ธนาคารกรุงเทพ" })}</option>
                                <option value="ธนาคารกสิกรไทย">{intl.formatMessage({ id: "113078", defaultMessage: "ธนาคารกสิกรไทย" })}</option>
                                <option value="ธนาคารกรุงไทย">{intl.formatMessage({ id: "113079", defaultMessage: "ธนาคารกสิกรไทย" })}</option>
                                <option value="ธนาคารทหารไทยธนชาต">{intl.formatMessage({ id: "113080", defaultMessage: "ธนาคารทหารไทยธนชาต" })}</option>
                                <option value="ธนาคารไทยพาณิชย์">{intl.formatMessage({ id: "113081", defaultMessage: "ธนาคารไทยพาณิชย์" })}</option>
                                <option value="ธนาคารกรุงศรีอยุธยา">{intl.formatMessage({ id: "113082", defaultMessage: "ธนาคารกรุงศรีอยุธยา" })}</option>
                                <option value="ธนาคารเกียรตินาคินภัทร">{intl.formatMessage({ id: "113083", defaultMessage: "ธนาคารเกียรตินาคินภัทร" })}</option>
                                <option value="ธนาคารซีไอเอ็มบีไทย">{intl.formatMessage({ id: "113084", defaultMessage: "ธนาคารซีไอเอ็มบีไทย" })}</option>\
                                <option value="ธนาคารทิสโก้">{intl.formatMessage({ id: "113085", defaultMessage: "ธนาคารทิสโก้" })}</option>
                                <option value="ธนาคารยูโอบี">{intl.formatMessage({ id: "113086", defaultMessage: "ธนาคารยูโอบี" })}</option>
                                <option value="ธนาคารแลนด์ แอนด์ เฮ้าส์">{intl.formatMessage({ id: "113087", defaultMessage: "ธนาคารแลนด์ แอนด์ เฮ้าส์" })}</option>
                                <option value="ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร">{intl.formatMessage({ id: "113088", defaultMessage: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร" })}</option>
                                <option value="ธนาคารออมสิน">{intl.formatMessage({ id: "113089", defaultMessage: "ธนาคารออมสิน" })}</option>
                                <option value="ธนาคารอาคารสงเคราะห์">{intl.formatMessage({ id: "113090", defaultMessage: "ธนาคารอาคารสงเคราะห์" })}</option>
                                <option value="ธนาคารอิสลามแห่งประเทศไทย">{intl.formatMessage({ id: "113091", defaultMessage: "ธนาคารอิสลามแห่งประเทศไทย" })}</option>
                                <option value="ธนาคารธนชาต">{intl.formatMessage({ id: "113092", defaultMessage: "ธนาคารธนชาต" })}</option>
                                <option value="ธนาคารไทยพัฒนา">{intl.formatMessage({ id: "113095", defaultMessage: "ธนาคารไทยพัฒนา" })}</option>
                                <option value="ธนาคารมหานคร">{intl.formatMessage({ id: "113096", defaultMessage: "ธนาคารมหานคร" })}</option>
                                <option value="ธนาคารมณฑล">{intl.formatMessage({ id: "113097", defaultMessage: "ธนาคารมณฑล" })}</option>
                                <option value="ธนาคารเกษตร">{intl.formatMessage({ id: "113098", defaultMessage: "ธนาคารเกษตร" })}</option>
                                <option value="ธนาคารนครธน">{intl.formatMessage({ id: "113099", defaultMessage: "ธนาคารนครธน" })}</option>
                            </select>
                        </div>

                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113072", defaultMessage: "Bank account name:" })}{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={payment_account_name}
                                onChange={(payment_account_name) => setpayment_account_name(payment_account_name.target.value)}
                            />
                        </div>



                        <div
                            className="form-inline"
                            style={{
                                marginBottom: "25px",
                            }}
                        >
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113073", defaultMessage: "Bank account number:" })}{" "}
                            </p>
                            <input
                                style={{
                                    height: "38px",
                                    width: "530px",
                                    backgroundColor: "#F4F6F9",
                                    borderRadius: "5px",
                                    borderWidth: "0",
                                }}
                                value={payment_account_number}
                                onChange={(payment_account_number) => setpayment_account_number(payment_account_number.target.value)}
                            />
                        </div>
                    </div>


                    <div className="container" style={{ border: "1px solid", borderRadius: "20px", borderColor: "#CECDCD", marginBottom: "20px", marginTop: "20px" }}>
                        <div className="h6" style={{ paddingTop: "20px" }}>

                            {intl.formatMessage({
                                id: "113240",
                                defaultMessage: "QR",
                            })}

                        </div>
                        <div className="form-inline" style={{ marginBottom: "25px" }}>
                            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                {intl.formatMessage({ id: "113240", defaultMessage: "QR" })} : <br></br>
                                (512*512)
                            </p>
                            <div
                                className="d-flex"
                                style={{
                                    width: "530px",
                                }}>
                                <input
                                    name="item_image_path_qr"
                                    style={{
                                        flexGrow: "1",
                                        height: "38px",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                    }}
                                    disabled
                                    value={item_image_path_qr}
                                />

                                <span
                                    className="btn btn-primary btn-file wbt pt-4"
                                    style={{
                                        whiteSpace: "nowrap",
                                        width: "fit-content",
                                    }}
                                >
                                    {intl.formatMessage({ id: "104025", defaultMessage: "Upload/Edit" })}
                                    <input type="file" onChange={handleChangeItem_image_path_qr} />
                                </span>

                                <button
                                    type="button"
                                    className="btn btn-danger wbt"
                                    onClick={removeImageItem_image_path_qr}
                                >
                                    {intl.formatMessage({ id: "104026", defaultMessage: "delete" })}
                                </button>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "50%",
                            }}
                        >
                            {stateItem_image_path_qr.previewItem_image_path ? (
                                <img
                                    style={{
                                        width: "300px",
                                        height: "300px",
                                    }}
                                    src={stateItem_image_path_qr.previewItem_image_path}
                                />
                            ) : item_image_path_qr ? (
                                <img
                                    style={{
                                        width: "300px",
                                        height: "300px",
                                    }}
                                    src={item_image_path_qr}
                                />
                            ) : null}
                        </div>
                        {stateItem_image_path_qr.imageURLItem_image_path ? (
                            <CropperImage
                                image={stateItem_image_path_qr.imageURLItem_image_path}
                                handleCancel={handleCancelUploadItem_image_path_qr}
                                handleUpload={handleImageUploadItem_image_path_qr}
                                scale={1}
                            />
                        ) : (
                            ""
                        )}




                        <div className="form-inline" style={{ marginBottom: "25px" }}>
                            <p
                                style={{
                                    marginTop: "15px",
                                    float: "left",
                                    width: "20%",
                                }}
                            >
                                {intl.formatMessage({ id: "113262", defaultMessage: "Enable Dynamic QR" })}
                            </p>
                            <Switch
                                checked={enableDynamicQR}
                                onChange={(e) => setEnableDynamicQR(e.target.checked)}
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                        </div>
                    </div>







                    <div
                        className="form-inline"
                        style={{
                            marginBottom: "25px",
                            marginTop: "25px",
                        }}
                    >
                        <div
                            style={{
                                marginTop: "15px",
                                float: "left",
                                width: "20%",
                            }}
                        ></div>
                        <Link to="/Shop">
                            <button type="button" className="btn btn-secondary wbt">
                                {intl.formatMessage({ id: "108031", defaultMessage: "cancel" })}
                            </button>
                        </Link>
                        <button className="btn btn-primary" onClick={() => handleSend()}>
                            {intl.formatMessage({ id: "108032", defaultMessage: "Save" })}
                        </button>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};
