import React from 'react';


class A5Template extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      table_name: this.props.table_name,
      qr_img: this.props.qr_img,
      cover: this.props.cover,
      logo: this.props.logo
    }
  }

  render() {
    return (
      <>
        <div id="testqrdiv" className="qrdiv" style={{
          backgroundImage: `url('/media/qr_template/A5A6-order.png')`,
          backgroundSize: '100%',
          height: '210mm',
          width: '148mm',
          position: 'relative',
          display:'none'
        }}>
          <img src={this.state.logo}
            width={111}
            height={111}
            style={{
              position: 'absolute',
              top: '14px',
              left: '13px',
              zIndex: '1',
              borderRadius: '50%',
              objectFit: 'cover'
            }}
            alt={this.state.logo} />
          <img src={this.state.cover}
            style={{
              position: 'absolute',
              height: '259px',
              top: '137px',
            }}
            alt={this.state.cover} />
          <div
            style={{
              position: 'absolute',
              background: '#fff',
              padding: '15px',
              borderRadius: '5px',
              left: '168px',
              top: '280px',
            }}>
            <img src={this.state.qr_img} alt="" width={200} height={200} />
          </div>
          <h1
            style={{
              position: 'absolute',
              right: '20px',
              top: '35px',
              fontSize: '60px',
              fontWeight: '900',
              color: '#fff'
            }}>
            {this.state.table_name}
          </h1>
        </div>
      </>
    )
  }
}

export default A5Template;
// eslint-disable-next-line no-lone-blocks
{/* <div class="A5-order" style="display: flex; position: relative;">
          <img src="'.'/uploads/restorants/'.$this->getRestaurant()->coverqr.'_qr.jpg'.'" alt="" class="img-food"/>
          <div class="tables '.$size.'" current-size="'.$size.'" style="position: absolute">
            <img class="logo-restaurant" src='.$this->getRestaurant()->icon.' width="70px" height="70px"/>
            <div class="header">
            </div>
            <div class="qr-code" >
              <img src="https://api.qrserver.com/v1/create-qr-code/?size=512x512&format=png&data='.$vendorURL.'/'.$val['id'].'&qzone=2" alt="" class="qr-img"/>
            </div>
            <div class="detail">
              <h1>'.$version.'</h1>
              <h5>SCAN TO VIEW MENU</h5>
              <img src="themes/page/assets/img/Slash-logo.png" alt="" class="logo-table"/>
            </div>
            <div class="table-number">
              <h3>'.$val['name'].'</h3>
            </div>
          </div>
        </div> */}