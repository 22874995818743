/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useSubheader } from "../../_metronic/layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { baseURL, baseURLDASHBOARD, imageURL } from "../service/API.js";
import Modal from "@material-ui/core/Modal";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Pagination from "react-pagination-library";
import Pagination2 from "@material-ui/lab/Pagination";
import "react-pagination-library/build/css/index.css";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { jsPDF } from 'jspdf'
import PropTypes from 'prop-types';
import CropperImage from "./component/CropperImage";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useIntl } from "react-intl";
import moment from "moment-timezone"
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

import A5Template from "./component/QRTemplate/A5Template";
import A6Template from "./component/QRTemplate/A6Template";
import A6HorizaltalTemplate from "./component/QRTemplate/A6HorizaltalTemplate";
import ThermalTemplate from "./component/QRTemplate/ThermalTemplate";
import StickerTemplate from "./component/QRTemplate/StickerTemplate";
import Sticker10x10Template from "./component/QRTemplate/10x10Template";
import { CoverQr } from "./CoverQR.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  root_tab: {
    flexGrow: 1,
  },
}));
export const TableZone = () => {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const suhbeader = useSubheader();
  const allDataTable = [];
  const allDataZone = [];
  const allDataDynamicQR = [];

  //// tableQr
  const [tableQr, setTableQr] = useState(0);
  const [respTableQr, setRespTableQr] = useState([]);
  const [zone, setZone] = useState([]);
  const [zoneCreate, setZoneCreate] = useState();
  const [qrSize, setQRSize] = useState([]);
  const [qrSizeCreate, setqrSizeCreate] = useState();
  const [cover_qr, setCover_QR] = useState();
  const [template_url, setTemplateUrl] = useState({
    id: null,
    url: null,
    isCoverUsed: true
  });
  const [nameImageItem_image_path, setNameImageItem_image_path] = useState("");
  const [stateItem_image_path, setStateItem_image_path] = useState({
    previewItem_image_path: null,
    rawItem_image_path: null,
    imageURLItem_image_path: null,
  });
  const [isLoadingGenAll, setIsLoadingGenAll] = useState(false);

  const centeredImageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  //// dynamic QR
  const [dynamicQR, setDynamicQR] = useState([]);
  const [perPageQR, setPerPageQR] = useState(10);
  const [lastPageQR, setLastPageQR] = useState(0);
  const [pageQR, setPageQR] = useState(1);

  //// table
  const [menuTable, setMenuTable] = useState([]);
  const [idTable, setIdTable] = useState("");
  const [perPageTable, setPerPageTable] = useState(10);
  const [lastPageTable, setLastPageTable] = useState(0);
  const [pageTable, setPageTable] = useState(1);
  const [openImageTable, setOpenImageTable] = useState(false);
  const [imgSelectTable, setImgSelectTable] = useState("");
  //// zone
  const [menuZone, setMenuZone] = useState([]);
  const [idZone, setIdZone] = useState("");
  const [totalZone, setTotalZone] = useState(0);
  const [perPageZone, setPerPageZone] = useState(10);
  const [lastPageZone, setLastPageZone] = useState(0);
  const [pageZone, setPageZone] = useState(1);
  const [openImageZone, setOpenImageZone] = useState(false);
  const [imgSelectZone, setImgSelectZone] = useState("");
  const [currentPageZone, setCurrentPageZone] = useState();
  const [selestSearchZone, setSelestSearchZone] = useState(1);
  const [reload, setReload] = useState(false);
  const [logo, setLogo] = useState();
  const classes = useStyles();
  suhbeader.setTitle(intl.formatMessage({ id: "113064" }));
  const history = useHistory();

  useEffect(() => {
    //console.log("*******qrsizecreate*******",qrSizeCreate)
    localStorage.removeItem('edit')
    getMenuTable(pageTable, perPageTable);
    getDynamicQR(pageQR, perPageQR)
    getMenuZone(pageZone, perPageZone);
    getZone();
    getQRSize();
    getSetting();
  }, [reload]);

  const handleChangeItem_image_path = async (e) => {
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      e.target.value = '';
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (/* height < 512 || width < 512 */false) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 512*512 photo size. !! ",
              "error"
            );
            e.target.value = '';
            return false;
          } else {
            setStateItem_image_path({
              rawItem_image_path: file,
              imageURLItem_image_path: e.target.result,
            });
            e.target.value = '';
            return true;
          }
        };
      };
    }
  };
  const handleImageUploadItem_image_path = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file, URL.createObjectURL(file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(`${baseURL}/upload/cover/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        setStateItem_image_path({
          previewItem_image_path: null,
          rawItem_image_path: null,
          imageURLItem_image_path: null,
        });
        setCover_QR(`${imageURL}/cover/cover-${res.filename}.${res.mimetype.split('/')[1]}`);
        setNameImageItem_image_path(res.filename + '.' + res.mimetype.split('/')[1]);
      })
      .catch((error) => console.log("error", error));
  };

  const removeImageItem_image_path = async () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null,
    });
    setCover_QR("");
  };

  const handleCancelUploadItem_image_path = () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null,
    });
  };

  ////////  COVER QR
  const getQRSize = async () => {
    console.log("******(get QR size)*******")
    let apiUrls = `${baseURL}/management/qrsize`;
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then(async (res) => {
        console.log("QR SIZE-------->",res.data.data);
        setQRSize(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////////  COVER QR
  const getSetting = async () => {
    console.log("******(get setting)*******")

    let apiUrls = `${baseURL}/management/settingQR`;
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then(async (res) => {
        setqrSizeCreate(res.data.data.qr_size_id)
        setCover_QR(res.data.data.cover_url)
        setLogo(res.data.logo)

        if (res.data.template_id > 3)
          setTemplateUrl({
            id: res.data.template_id,
            url: res.data.template_url,
            isCoverUsed: false
          })
        else
          setTemplateUrl({
            id: res.data.template_id,
            url: res.data.template_url,
            isCoverUsed: true
          })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////////  Table
  //get zone
  const getZone = async () => {
    let apiUrls = `${baseURL}/management/tablezone?limit=100&page=1`;
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then(async (res) => {
        console.log(res.data.data);
        setZone(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////////  Table
  const getMenuTable = async (page, perPage) => {
    let apiUrls = `${baseURL}/management/table?limit=${perPage}&page=${page}${searchTable !== undefined && searchTable !== "" ? "&search=" + searchTable : ""}`;
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then(async (res) => {
        await res.data.data.map(async (data, index) => {
          data = {
            id: data.id,
            zone_id: data.zone_id,
            branch_id: data.branch_id,
            table_name: data.table_name,
            table_name_en: data.table_name_en,
            table_desc: data.table_desc,
            table_desc_en: data.table_desc_en,
            max_seat: data.max_seat,
            created_at: data.created_at ? moment(data.created_at).format("YYYY-MM-DD HH:mm:ss") : null,
            zone_name: data.zone_name,
            enabled: data.enabled,
            cover_qr: data.cover_qr,
            qrLink: data.qr ? data.qr.qr_link_1 : null,
            qrCode: data.qr ? data.qr.code : null,
            is_pickup: data.is_pickup,
            is_delivery: data.is_delivery,
            is_member_no: data.is_member_no,
            qr_address: data.qr_address ?? '-',
            is_dynamic: data.qr?.is_dynamic === true ? data.qr.is_dynamic : false,
            table_type: data.table_type ? data.table_type : null
          };
          allDataTable.push(data);
        });
        setLastPageTable(Math.ceil(Number(res.data.total) / Number(res.data.limit)));
        setPageTable(res.data.page)
        setPerPageTable(res.data.limit)
        setMenuTable(allDataTable);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////////  DynamicQR
  const getDynamicQR = async (page, perPage) => {
    let apiUrls = `${baseURL}/qr/dynamic-list?limit=${perPage}&page=${page}${searchQrDynamic !== undefined && searchQrDynamic !== "" ? "&search=" + searchQrDynamic : ""}`;
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then(async (res) => {
        await res.data.data.map(async (data, index) => {
          data = {
            id: data.id,
            branch_id: data.branch_id,
            table_name: data.table.table_name,
            duration_time: data.duration_time,
            created_at: data.created_at ? moment(data.created_at).format("YYYY-MM-DD HH:mm:ss") : null,
            dynamic_at: data.dynamic_at ? moment(data.dynamic_at).format("YYYY-MM-DD HH:mm:ss") : null,
            enabled: data.enabled,
            qrLink: data.qr_link_1 ? data.qr_link_1 : null,
            qrCode: data.code ? data.code : null,
          };
          allDataDynamicQR.push(data);
        });
        let numberTable = Number(res.data.total) / Number(res.data.limit);
        setLastPageQR(Math.ceil(numberTable));
        setPageQR(res.data.page)
        setPerPageQR(res.data.limit)
        setDynamicQR(allDataDynamicQR);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns3 = [
    {
      dataField: "table_name",
      text: intl.formatMessage({ id: "113250", defaultMessage: "Table" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>{row.table_name}</p>
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "112007", defaultMessage: "Created at" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>{row.dynamic_at}</p>
          </div>
        );
      },
    },
    {
      text: "",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '200px' }}>
            <button
              type="button"
              className="btn btn-danger btn-sm mr-2"
              disabled={row.enabled === false}
              onClick={() => closeTable({ row, rowIndex })}
            >
              {intl.formatMessage({ id: "113261", defaultMessage: "Close" })}
            </button>
          </div>
        );
      },
    },
  ]

  const closeTable = (data) => {
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: intl.formatMessage({ id: "113040", defaultMessage: "Are you sure?" }),
      text: intl.formatMessage({ id: "113041", defaultMessage: "You will not be able to recover this Menu !" }),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "113042", defaultMessage: "Yes, delete it!" }),
      cancelButtonText: intl.formatMessage({ id: "113043", defaultMessage: "No, keep it" }),

    }).then(async (result) => {
      if (result.value) {
        await axios({
          method: "put",
          url: `${baseURL}/qr/close-qr/${data.row.id}`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            Swal.fire(
              intl.formatMessage({ id: "113047", defaultMessage: "Deleted!" }),
              intl.formatMessage({ id: "113048", defaultMessage: "Your Menu has been deleted." }),
              "success"
            );
            getDynamicQR(pageTable, perPageTable);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your Menu  has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(intl.formatMessage({ id: "113044", defaultMessage: "Cancelled" }),
          intl.formatMessage({ id: "113045", defaultMessage: "Your Menu is safe :)" }),
          "error");
      }
    });
  };

  const columns1 = [
    {
      dataField: "table_name",
      text: intl.formatMessage({ id: "113005", defaultMessage: "Thai name" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>{row.table_name}</p>
          </div>
        );
      },
    },
    {
      dataField: "table_name_en",
      text: intl.formatMessage({ id: "113006", defaultMessage: "English name" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>{row.table_name_en}</p>
          </div>
        );
      },
    },
    {
      dataField: "zone_name",
      text: intl.formatMessage({ id: "113007", defaultMessage: "Zone name" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>{row.zone_name}</p>
          </div>
        );
      },
    },
    {
      dataField: "max_seat",
      text: intl.formatMessage({ id: "113008", defaultMessage: "Number of seats" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>{row.max_seat}</p>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: intl.formatMessage({ id: "113240", defaultMessage: "QR" }),
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            {row.qrLink && <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "45%", width: "45%" }}
              id={row.qrCode}
              value={row.qrLink} />}
          </div>
        );
      },
    },
    {
      dataField: "is_pickup",
      text: intl.formatMessage({ id: "113356", defaultMessage: "Pick up" }),
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>
              {
                row.is_pickup ?
                  <span style={{ color: "green" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>
              }
            </p>
          </div>
        );
      },
    },
    {
      dataField: "is_dynamic",
      text: intl.formatMessage({ id: "113347", defaultMessage: "Dynamic QR" }),
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>
              {
                row.is_dynamic ?
                  <span style={{ color: "green" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>
              }
            </p>
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "113009", defaultMessage: "Date" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>{row.created_at ? row.created_at : '-'}</p>
          </div>
        );
      },
    },
    {
      text: "",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '150px' }}>
            <button
              type="button"
              className="btn btn-primary btn-sm mr-2"
              onClick={() => editItemTable({ id, row, rowIndex })}
            >
              {intl.formatMessage({ id: "113010", defaultMessage: "Edit" })}
            </button>

            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => deleteItemTable({ row, rowIndex })}
            >
              {intl.formatMessage({ id: "113011", defaultMessage: "Delete" })}
            </button>
          </div>
        );
      },
    },
    {
      text: "",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '200px' }}>
            <button
              type="button"
              className="btn btn-primary btn-sm mr-2"
              disabled={row.is_dynamic ? true : false}
              onClick={() => generateQr({ id, row, rowIndex })}
            >
              {intl.formatMessage({ id: "113241", defaultMessage: "Re Generate" })}
            </button>
            {/* 
            <button
              type="button"
              disabled={row.qrLink ? false : true}
              className="btn btn-primary btn-sm"
              onClick={() => downloadQr({ row, rowIndex })}
            >
              {intl.formatMessage({ id: "113242", defaultMessage: "Download" })}
            </button> */}
          </div>
        );
      },
    },
  ];

  const handleCloseTable = () => {
    setIdTable("");
    setOpenImageTable(false);
    setImgSelectTable("");
  };

  const editItemTable = (data) => {
    history.push("/EditTable", data);
  };

  const deleteItemTable = (data) => {
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: intl.formatMessage({ id: "113040", defaultMessage: "Are you sure?" }),
      text: intl.formatMessage({ id: "113359", defaultMessage: "You will not be able to recover this Menu !" }),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "113042", defaultMessage: "Yes, delete it!" }),
      cancelButtonText: intl.formatMessage({ id: "113043", defaultMessage: "No, keep it" }),

    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/management/table/${data.row.id}`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            Swal.fire(
              intl.formatMessage({ id: "113047", defaultMessage: "Deleted!" }),
              intl.formatMessage({ id: "113048", defaultMessage: "Your Menu has been deleted." }),
              "success"
            );
            getMenuTable(pageTable, perPageTable);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your Menu  has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(intl.formatMessage({ id: "113044", defaultMessage: "Cancelled" }),
          intl.formatMessage({ id: "113045", defaultMessage: "Your Menu is safe :)" }),
          "error");
      }
    });
  };

  const [searchTable, setSearchTable] = useState();
  const [searchQrDynamic, setSearchQrDynamic] = useState();

  const handleSearchTable = () => {
    getMenuTable(pageTable, perPageTable);
  };

  const handleSearchQrDynamic = () => {
    getDynamicQR(pageTable, perPageTable);
  };

  ////////  zone
  const getMenuZone = async (pageZone, perPageZone) => {
    let apiUrls = `${baseURL}/management/tablezone?limit=${perPageZone}&page=${pageZone}${searchZone !== undefined && searchZone !== "" ? "&search=" + searchZone : ""}`;
    let token = localStorage.getItem("Token");
    await axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        await res.data.data.map(async (data, index) => {
          data = {
            created_at: data.created_at ? moment(data.created_at).format("YYYY-MM-DD HH:mm:ss") : null,
            id: data.id,
            zone_name: data.zone_name,
            zone_name_en: data.zone_name_en,
            zone_desc: data.zone_desc,
            zone_desc_en: data.zone_desc_en,
            enabled: data.enabled,

          };
          allDataZone.push(data);
        });
        var numberZone = Number(res.data.total) / Number(res.data.limit);
        setLastPageZone(Math.ceil(numberZone));
        setPageZone(res.data.page)
        setPerPageZone(res.data.limit)
        setMenuZone(allDataZone);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns2 = [
    {
      dataField: "zone_name",
      text: intl.formatMessage({ id: "113016", defaultMessage: "Name th" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "zone_name_en",
      text: intl.formatMessage({ id: "113017", defaultMessage: "Name en" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "113018", defaultMessage: "Date" }),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div style={{ width: '120px' }}>
            <p>{row.created_at ? row.created_at : '-'}</p>
          </div>
        );
      },
    },

    {
      text: "",
      dataField: "id",
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => editItemZone({ id, row, rowIndex })}
            >
              {intl.formatMessage({ id: "113019", defaultMessage: "Edit" })}
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => deleteItemZone({ row, rowIndex })}
            >
              {intl.formatMessage({ id: "113020", defaultMessage: "Delete" })}
            </button>
          </div>
        );
      },
    },

  ];

  const handleCloseZone = () => {
    setIdZone("");
    setOpenImageZone(false);
    setImgSelectZone("");
  };

  const editItemZone = (data) => {
    history.push("/EditZone", data);
  };

  const generateQr = (data) => {
    Swal.fire({
      title: intl.formatMessage({ id: "113243", defaultMessage: "Confirm Generate ?" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "113244", defaultMessage: "Confirm" }),
      cancelButtonText: intl.formatMessage({ id: "113245", defaultMessage: "Cancel" }),
    }).then(async (result) => {
      if (result.value) {
        let token = localStorage.getItem("Token");
        let apiUrls = `${baseURL}/management/table-re-qr/${data.id}`
        let config = {
          method: 'post',
          url: apiUrls,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
        await axios.request(config)
          .then((res) => {
            Swal.fire(
              intl.formatMessage({ id: "113246", defaultMessage: "Success" }),
              intl.formatMessage({ id: "113247", defaultMessage: "Success Generate QR" }),
              "success"
            ).then(async () => {
              await getMenuTable(pageTable, perPageTable);
            })
          })
          .catch((err) => {
            Swal.fire(
              "Error !",
              "Generate Error.",
              "error"
            );
          });
      }
    });
  };

  async function generatePdf(size, ali) {

    let canvases = document.querySelectorAll('.qrdiv');

    const pdf = new jsPDF(ali, 'mm', size);

    //console.log(canvases)


    for (let i = 0; i < canvases.length; i++) {
      //console.log(canvases[i])
      let rendered = await html2canvas(canvases[i], {
        useCORS: true,
        proxy: "Server",
        scale: 2,
        onclone: function (clonedDoc) {
          //console.log(clonedDoc)
          clonedDoc.querySelectorAll('.qrdiv')[i].style.display = 'block';
        }


      })
        //.log(rendered)
        .then((canvas) => {
          console.log(canvas)
          if (i > 0) {

            pdf.addPage();
          }

          pdf.setPage(i + 1);
          let dataURL = canvas.toDataURL('image/jpeg');
          //console.log(dataURL);
          pdf.addImage(canvas, 'PNG', 0, 0, size[0], size[1]);
        })
      /* await html2canvas(canvases[i], {
          useCORS: true,
          proxy: "Server",
          scale: 2,
          onclone: function(clonedDoc) {
              clonedDoc.getElementById('printsource').style.display = 'block';
          }
      }).then(canvas => {
          if (i > 0) {
              pdf.addPage();
          }

          pdf.setPage(i + 1);
          let dataURL = canvas.toDataURL('image/jpeg');
          //console.log(dataURL);
          pdf.addImage(canvas, 'PNG', 0, 0, size[0], size[1]);
      }) */
    }

    //console.log(pdf);
    pdf.save('downloadQR.pdf');
  }

  const generateQrAll = (data) => {
    Swal.fire({
      title: intl.formatMessage({ id: "113243", defaultMessage: "Confirm Generate ?" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "113244", defaultMessage: "Confirm" }),
      cancelButtonText: intl.formatMessage({ id: "113245", defaultMessage: "Cancel" }),
    }).then(async (result) => {
      if (result.value) {
        setIsLoadingGenAll(true)
        let token = localStorage.getItem("Token");
        let apiUrls = `${baseURL}/management/table-qr`
        let config = {
          method: 'post',
          url: apiUrls,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
        await axios.request(config)
          .then((res) => {
            setIsLoadingGenAll(false)
            Swal.fire(
              intl.formatMessage({ id: "113246", defaultMessage: "Success" }),
              intl.formatMessage({ id: "113247", defaultMessage: "Success Generate QR" }),
              "success"
            ).then(async () => {
              await getMenuTable(pageTable, perPageTable);
            })
          })
          .catch((err) => {
            setIsLoadingGenAll(false)
            Swal.fire(
              "Error !",
              "Generate Error.",
              "error"
            );
          });
      }
    });
  };



  const downloadAllQr = () => {
    Swal.fire({
      title: 'Now loading',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 10000,
      onOpen: () => {
        Swal.showLoading();
      }
    }).then(
      () => {},
      (dismiss) => {
        if (dismiss === 'timer') {
          console.log('closed by timer!!!!');
          Swal.fire({ 
            title: 'Finished!',
            type: 'success',
            timer: 2000,
            showConfirmButton: false
          })
        }
      }
    )

    let size = [];
    let ali = 'p;'

    let container = document.getElementById('qr_render');

    menuTable.forEach((table) => {
      //console.log(table)
      let base64Image = document.getElementById(table.qrCode).toDataURL('image/png')


      let element;
      console.log("*******(qrSizeCreate)*******",qrSizeCreate)

      switch (qrSizeCreate) {
        case 2:
          size = [105, 148.5];

          element = (
            <React.StrictMode>
              <A6Template
                table_name={table.table_name}
                qr_img={base64Image}
                cover={cover_qr}
                logo={logo}
              />
            </React.StrictMode>
          );
          break;
        case 3:
          size = [153.5, 108.5];
          ali = 'l'
          element = (
            <React.StrictMode>
              <A6HorizaltalTemplate
                table_name={table.table_name}
                qr_img={base64Image}
                cover={cover_qr}
                logo={logo}
              />
            </React.StrictMode>
          );
          break;
        case 4:
          size = [120, 75];
          ali = 'l'
          element = (
            <React.StrictMode>
              <ThermalTemplate
                table_name={table.table_name}
                qr_img={base64Image}
                cover={cover_qr}
              />
            </React.StrictMode>
          );
          break;
        case 5:
          size = [55, 80];
          element = (
            <React.StrictMode>
              <StickerTemplate
                table_name={table.table_name}
                qr_img={base64Image}
                cover={cover_qr}
              />
            </React.StrictMode>
          );
          break;
        case 6:
          size = [103.5, 103.5];
          element = (
            <React.StrictMode>
              <Sticker10x10Template
                table_name={table.table_name}
                qr_img={base64Image}
                cover={cover_qr}
              />
            </React.StrictMode>
          );
          break;
        default:
          size = [148, 210];
          element = (
            <React.StrictMode>
              <A5Template
                table_name={table.table_name}
                qr_img={base64Image}
                cover={cover_qr}
                logo={logo}
              />
            </React.StrictMode>
          );
          break;
      }
      // Create a new div for each element
      const div = document.createElement('div');
      container.appendChild(div);

      // Render the element into the newly created div
      ReactDOM.render(element, div);// Render the element into the container
      ReactDOM.render(element, container);
    })

    //console.log(container)


    setTimeout(() => {
      generatePdf(size, ali);
    }, 0); // Use a setTimeout with a delay of 0 to ensure it runs after the rendering

    //ReactDOM.render(null, document.getElementById('qr_render'));


  }

  const downloadQr = (data) => {

    let size = [];
    let ali = 'p;'
    let base64Image = document.getElementById(data.row.qrCode).toDataURL('image/png')


    switch (qrSizeCreate) {
      case 2:
        size = [105, 148.5];

        ReactDOM.render(
          <React.StrictMode>
            <A6Template
              table_name={data.row.table_name}
              qr_img={base64Image}
              cover={cover_qr}
              logo={logo}
            />
          </React.StrictMode>,
          document.getElementById('qr_render')
        );
        break;
      case 3:
        size = [153.5, 108.5];
        ali = 'l'
        ReactDOM.render(
          <React.StrictMode>
            <A6HorizaltalTemplate
              table_name={data.row.table_name}
              qr_img={base64Image}
              cover={cover_qr}
              logo={logo}
            />
          </React.StrictMode>,
          document.getElementById('qr_render')
        );
        break;
      case 4:
        size = [120, 75];
        ali = 'l'
        ReactDOM.render(
          <React.StrictMode>
            <ThermalTemplate
              table_name={data.row.table_name}
              qr_img={base64Image}
              cover={cover_qr}
            />
          </React.StrictMode>,
          document.getElementById('qr_render')
        );
        break;
      case 5:
        size = [55, 80];
        ReactDOM.render(
          <React.StrictMode>
            <StickerTemplate
              table_name={data.row.table_name}
              qr_img={base64Image}
              cover={cover_qr}
            />
          </React.StrictMode>,
          document.getElementById('qr_render')
        );
        break;
      case 6:
        size = [103.5, 103.5];
        ReactDOM.render(
          <React.StrictMode>
            <Sticker10x10Template
              table_name={data.row.table_name}
              qr_img={base64Image}
              cover={cover_qr}
            />
          </React.StrictMode>,
          document.getElementById('qr_render')
        );
        break;
      default:
        size = [148, 210];
        ReactDOM.render(
          <React.StrictMode>
            <A5Template
              table_name={data.row.table_name}
              qr_img={base64Image}
              cover={cover_qr}
              logo={logo}
            />
          </React.StrictMode>,
          document.getElementById('qr_render')
        );
        break;
    }

    setTimeout(() => {
      const pdf = new jsPDF(ali, 'mm', size);

      html2canvas(document.getElementById('testqrdiv'), {
        useCORS: true,
        proxy: "Server",
        scale: 2,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById('testqrdiv').style.display = 'block';
        }
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData, 'PNG', 0, 0, size[0], size[1]);
        pdf.save('div_image.pdf');
        ReactDOM.render(null, document.getElementById('qr_render'));
      });
    }, 0); // Use a setTimeout with a delay of 0 to ensure it runs after the rendering
  }

  const deleteItemZone = (data) => {
    let token = localStorage.getItem("Token");
    Swal.fire({
      title: intl.formatMessage({ id: "113040", defaultMessage: "Are you sure?" }),
      text: intl.formatMessage({ id: "113041", defaultMessage: "You will not be able to recover this Menu !" }),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "113042", defaultMessage: "Yes, delete it!" }),
      cancelButtonText: intl.formatMessage({ id: "113043", defaultMessage: "No, keep it" }),
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${baseURL}/management/tablezone/${data.row.id}`, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            Swal.fire(
              intl.formatMessage({ id: "113047", defaultMessage: "Deleted!" }),
              intl.formatMessage({ id: "113048", defaultMessage: "Your Menu has been deleted." }),
              "success"
            );
            getMenuZone(pageZone, perPageZone);
          })
          .catch((err) => {
            Swal.fire(
              "Deleted!",
              "Your Menu  has been fail to deleted.",
              "error"
            );
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(intl.formatMessage({ id: "113044", defaultMessage: "Cancelled" }),
          intl.formatMessage({ id: "113045", defaultMessage: "Your Menu is safe :)" }),
          "error");
      }
    });
  };

  const [searchZone, setSearchZone] = useState();
  const handleSearchZone = () => {
    getMenuZone(pageZone, perPageZone);
  };

  const handleChangeSelestSearchZone = (event) => {
    setSelestSearchZone(Number(event.target.value));
  };

  const [value, setValue] = useState(() => {
    // ดึงค่าเริ่มต้นจาก localStorage ถ้ามีค่าเก็บไว้ ถ้าไม่มีให้ตั้งเป็น 0
    const storedValue = localStorage.getItem('storedValue');
    return storedValue ? Number(storedValue) : 0;
  });
  useEffect(() => {
    const storedValue = localStorage.getItem('storedValue');
    if (storedValue) {
      setValue(Number(storedValue));
    }
  }, []);
  const handleChange = (event, newValue) => {
    // เก็บค่าใน state
    setValue(newValue);
  
    // เก็บค่าใน localStorage
    localStorage.setItem('storedValue', newValue);
  };

  const handlePageTableSizeChange = (event) => {
    setPageTable(1);
    setPerPageTable(event.target.value);
    getMenuTable(1, event.target.value);
  };

  const handlePageTableChange = (event, value) => {
    setPageTable(value);
    getMenuTable(value, perPageTable);
  };

  const handlePageZoneSizeChange = (event) => {
    setPageZone(1);
    setPerPageZone(event.target.value);
    getMenuZone(1, event.target.value);
  };

  const handlePageZoneChange = (event, value) => {
    setPageZone(value);
    console.log('page :', value);
    console.log('perPageZone :', perPageZone);
    getMenuZone(value, perPageZone);
  };
/* qrselectchange */
const handleSelectChange = (type, size, e) => {
    setqrSizeCreate(e.target.value);
    setSizeQrShow(type,size)
    setSizeCoverShow(type,size)
  };
  //set size function
  const setSizeQrShow = (type,size)=>{
     console.log("*******(size)*******",typeof(size))
     console.log("*******(type)*******",type)
    if(type && (type && size === "1" || type && size === "2" || type && size === 1 || type && size === 2)){
      console.log("-------1--------")
      return { width: "596px", height: "840px", display: 'block', position: 'relative' }
    }else if( type && size === "3" || type && size === 3){
      console.log("-------3--------")
      return { width: "850px", height: "596px", display: 'block', position: 'relative' }
    }else if(type && size === "4" || type && size === 4){
      console.log("-------4--------")
      return {width: "468px",height: "681px", display: 'block' , position: 'relative'}

    }else if(type && size === "5" || type && size ===5){
      console.log("-------5--------")
      return {width: "468px",height: "681px", display: 'block' , position: 'relative'}
    }else if(type && size === "6" || type && size === 6){
      console.log("-------6--------")
      return { width: "566px",height: "566px", display: 'block', position: 'relative'}
    } else if (type && size === "7" || type && size === 7) {
      console.log("-------7--------")
      return { width: "680px", height: "426px", display: 'block', position: 'relative' }
    }
    return {
      
      width: "596px",
        height: "840px"

    };

  }
  /*setsizecoverqr */
  const setSizeCoverShow = (type,size)=>{
    
   if(type && (type && size === "1" || type && size === "2" || type && size === 1 || type && size === 2)){
     console.log("-------cover1--------")
     var style = {position: "absolute",top: "0", left: "0", width: "596px", height: "840px",zIndex: 1,}
     var imageUrl = '../../media/qr_template/A6A5-cover-view.png'
     return {  src: imageUrl,style: style }
   }else if( type && size === "3" || type && size === 3){
     console.log("-------cover3--------")
     var style = {position: "absolute",top: "0", left: "0", width: "850px", height: "596px",zIndex: 1,}
     var imageUrl = '../../media/qr_template/150X105mm-cover-view.png'
     return {  src: imageUrl,style: style }
   }else if(type && size === "4"||type && size === 4 || type && size === "5"||type && size === 5 ){
     console.log("-------cover4--------")
     var style = {position: "absolute",top: "0", left: "0", width: "468px", height: "681px",zIndex: 1,}
     var imageUrl = '../../media/qr_template/55X80mm-cover-view.png'
     return {  src: imageUrl,style: style }
   }else if(type && size === "6" || type && size === 6 ){
     console.log("-------cover6--------")
     var style = {position: "absolute",top: "0", left: "0", width: "566px", height: "566px",zIndex: 1,}
     var imageUrl = '../../media/qr_template/10x10cm-cover-view.png'
     return {  src: imageUrl,style: style }
   }else if(type && size === "7" || type && size === 7){
    var style = {position: "absolute",top: "0", left: "0", width: "680px", height: "426px",zIndex: 1,}
    var imageUrl = '../../media/qr_template/120x75mm-cover-view.png'
    return {  src: imageUrl,style: style }
   }
   return {
     width: "596px",
       height: "840px"

   };

 }

  //quick create table
  const handleCreate = async () => {
    Swal.fire({
      title: intl.formatMessage({ id: "113251", defaultMessage: "Comfirm Create Table ?" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({ id: "113244", defaultMessage: "Confirm" }),
      cancelButtonText: intl.formatMessage({ id: "113245", defaultMessage: "Cancel" }),
    }).then(async (result) => {
      if (result.value) {
        let token = localStorage.getItem("Token");
        let apiUrls = `${baseURL}/management/table-qr-insert`
        let data = {
          table: Number(tableQr),
          zone_id: zoneCreate
        }
        let config = {
          method: 'post',
          url: apiUrls,
          headers: {
            Authorization: `Bearer ${token}`
          },
          data: data
        }
        await axios.request(config)
          .then((res) => {
            Swal.fire(
              intl.formatMessage({ id: "113246", defaultMessage: "Success" }),
              intl.formatMessage({ id: "113247", defaultMessage: "Success Generate QR" }),
              "success"
            ).then(() => {
              setRespTableQr(res.data.data)
              setTableQr(0)
            })
          })
          .catch((err) => {
            Swal.fire(
              "Error !",
              "Create Error.",
              "error"
            );
          });
      }
    });
  };

  const handleQRSetting = async () => {
    let token = localStorage.getItem("Token");
    let apiUrls = `${baseURL}/management//settingQR`
    let data = {
      qr_size_id: qrSizeCreate,
      cover_url: cover_qr,
      shop_id: localStorage.getItem("shop_id")
    }
    let config = {
      method: 'post',
      url: apiUrls,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: data
    }
    await axios.request(config)
      .then((res) => {
        Swal.fire(
          intl.formatMessage({ id: "113246", defaultMessage: "Success" }),
          intl.formatMessage({ id: "113247", defaultMessage: "Success QR Setting" }),
          "success"
        ).then(() => {
          setqrSizeCreate(res.data.qr_size_id);
          setReload(!reload);
        })
      })
      .catch((err) => {
        Swal.fire(
          "Error !",
          "Create Error.",
          "error"
        );
      });
  };

  return (
    <Card>
      <div className={classes.root_tab}>
        <AppBar position="static" style={{ background: '#1E1E2D', color: "#fff" }}>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label={intl.formatMessage({ id: "113001", defaultMessage: "Table" })} {...a11yProps(0)} />
            <Tab label={intl.formatMessage({ id: "113012", defaultMessage: "Zone" })} {...a11yProps(1)} />
            <Tab label={intl.formatMessage({ id: "113421", defaultMessage: "Sticker QR" })} {...a11yProps(2)} />
            <Tab label={intl.formatMessage({ id: "113240", defaultMessage: "QR" })} {...a11yProps(3)} />
            <Tab label={intl.formatMessage({  id: "113258", defaultMessage: "Dynamic QR"  })} {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113001", defaultMessage: "Table" })}>
              <CardHeaderToolbar>
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => generateQrAll()}
                  disabled={isLoadingGenAll}
                >
                  {intl.formatMessage({ id: "113248", defaultMessage: "Generate All" })}
                </button>

                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => downloadAllQr()}
                >
                  {intl.formatMessage({ id: "113249", defaultMessage: "Download All" })}
                </button>
                <Link to="/CreateTable">
                  <button type="button" className="btn btn-primary">
                    {intl.formatMessage({ id: "113002", defaultMessage: "Create" })}
                  </button>
                </Link>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <div id="qr_render"></div>
              <div className="form-row align-items-center">
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "113003", defaultMessage: "Text Search" })}
                    style={{ width: "100%" }}
                    onChange={(e) => setSearchTable(e.target.value)}
                  />
                </div>
                <div className="col-sm-2">
                  <button
                    type="button"
                    id="button"
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                    onClick={() => handleSearchTable()}
                  >
                    {intl.formatMessage({ id: "113004", defaultMessage: "Search" })}
                  </button>
                </div>
              </div>

              <BootstrapTable
                keyField="id"
                data={menuTable}
                columns={columns1}
                wrapperClasses="table-responsive table-cm"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
              />
              <div className=" col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mt-3">
                      {intl.formatMessage({ id: "107032", defaultMessage: "Item per page" })}
                      <select onChange={handlePageTableSizeChange} value={perPageTable}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Pagination2
                      className="mt-3 "
                      count={lastPageTable}
                      page={pageTable}
                      siblingCount={1}
                      boundaryCount={1}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageTableChange}
                    />
                  </div>
                </div>
              </div>
            </CardBody>

            <Modal
              open={openImageTable}
              disablePortal
              disableEnforceFocus
              disableAutoFocus
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <TransformComponent>
                          <div className="modal-body">
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: "500px",
                                maxHeight: "500px",
                              }}
                              src={imgSelectTable}
                            />
                          </div>
                        </TransformComponent>
                        <div className="modal-footer">
                          <div className="tools">
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              onClick={zoomIn}
                            >
                              +
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              onClick={zoomOut}
                            >
                              -
                            </button>
                          </div>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => handleCloseTable()}
                          >
                            Close
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </div>
              </div>
            </Modal>
          </Card>

        </TabPanel>
        <TabPanel value={value} index={1}>
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113012", defaultMessage: "Zone" })}>
              <CardHeaderToolbar>
                <Link to="/CreateZone">
                  <button type="button" className="btn btn-primary">
                    {intl.formatMessage({ id: "113013", defaultMessage: "Create" })}
                  </button>
                </Link>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <div className="form-row align-items-center">
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "113014", defaultMessage: "Search Text" })}
                    style={{ width: "100%" }}
                    onChange={(e) => setSearchZone(e.target.value)}
                  />
                </div>
                <div className="col-sm-2">
                  <button
                    type="button"
                    id="button"
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                    onClick={() => handleSearchZone()}
                  >
                    {intl.formatMessage({ id: "113015", defaultMessage: "Search" })}
                  </button>
                </div>
              </div>

              <BootstrapTable
                keyField="id"
                data={menuZone}
                columns={columns2}
                wrapperClasses="table-responsive table-cm"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
              />
              <div className=" col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mt-3">
                      {intl.formatMessage({ id: "107032", defaultMessage: "Item per page" })}
                      <select onChange={handlePageZoneSizeChange} value={perPageZone}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Pagination2
                      className="mt-3 "
                      count={lastPageZone}
                      page={pageZone}
                      siblingCount={1}
                      boundaryCount={1}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageZoneChange}
                    />
                  </div>
                </div>
              </div>
            </CardBody>

            <Modal
              open={openImageZone}
              disablePortal
              disableEnforceFocus
              disableAutoFocus
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <TransformComponent>
                          <div className="modal-body">
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: "500px",
                                maxHeight: "500px",
                              }}
                              src={imgSelectZone}
                            />
                          </div>
                        </TransformComponent>
                        <div className="modal-footer">
                          <div className="tools">
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              onClick={zoomIn}
                            >
                              +
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              onClick={zoomOut}
                            >
                              -
                            </button>
                          </div>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => handleCloseZone()}
                          >
                            Close
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </div>
              </div>
            </Modal>
          </Card>

        </TabPanel>
        <TabPanel value={value} index={2}>
        <CoverQr/>
          

        </TabPanel>
        <TabPanel value={value} index={3}>
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113240", defaultMessage: "QR" })}></CardHeader>
            <CardBody>
              <div className="container">
                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >

                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    {intl.formatMessage({ id: "113250", defaultMessage: "Table" })} :{" "}
                  </p>

                  <div
                    className="d-flex mr-2"
                    style={{
                      width: "530px",
                    }}>
                    <input
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      type="number"
                      onKeyDown={(e) => {
                        // Check if the key pressed is 'e' or 'E'
                        if (e.key === 'e' || e.key === 'E') {
                          e.preventDefault(); // Prevent the default behavior (input of 'e' or 'E')
                        }
                      }}

                      onChange={(e) => setTableQr(e.target.value)}
                    />

                  </div>

                </div>

                <div
                  className="form-inline"
                  style={{
                    marginBottom: "25px",
                  }}
                >

                  <p
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    {intl.formatMessage({ id: "113012", defaultMessage: "Zone" })} :{" "}
                  </p>

                  <div
                    className="d-flex mr-2"
                    style={{
                      width: "530px",
                    }}>
                    <select
                      as="select"
                      style={{
                        flexGrow: "1",
                        height: "38px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      value={zoneCreate}
                      onChange={(e) => setZoneCreate(e.target.value)}
                    >
                      <option value="">
                        {intl.formatMessage({
                          id: "104035",
                          defaultMessage: "please select",
                        })}
                      </option>
                      {zone &&
                        zone.map((result, index) => {
                          return (
                            <option key={index} value={result.id}>
                              {result.zone_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <button className="btn btn-primary" onClick={() => handleCreate()}>
                    Save
                  </button>
                </div>
              </div>


            </CardBody>

            {respTableQr.length !== 0 &&
              respTableQr.map(item => {
                return (
                  <CardBody>
                    <div className="container">
                      <div
                        className="form-inline"
                        style={{
                          marginBottom: "25px",
                        }}
                      >

                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          {intl.formatMessage({ id: "113250", defaultMessage: "Table" })} :{" "}
                        </p>

                        <div
                          className="d-flex mr-2"
                          style={{
                            width: "530px",
                          }}>
                          <input
                            style={{
                              height: "38px",
                              width: "530px",
                              backgroundColor: "#F4F6F9",
                              borderRadius: "5px",
                              borderWidth: "0",
                            }}
                            type="text"
                            disabled={true}
                            value={item.table_name}
                          />

                        </div>
                      </div>


                      <div
                        className="form-inline"
                        style={{
                          marginBottom: "25px",
                        }}
                      >

                        <p
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "20%",
                          }}
                        >
                          {intl.formatMessage({ id: "113240", defaultMessage: "QR" })} :{" "}
                        </p>

                        <div
                          className="d-flex mr-2"
                          style={{
                            width: "530px",
                          }}>
                          <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "30%", width: "30%" }}
                            // id={}
                            value={item.qr_link_1} />
                        </div>
                      </div>
                    </div>
                  </CardBody>)
              })
            }
          </Card>

        </TabPanel>

        <TabPanel value={value} index={4}>
 
          <Card>
            <CardHeader title={intl.formatMessage({ id: "113258", defaultMessage: "Dynamic QR" })}>
              <CardHeaderToolbar>
                {/* <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={() => generateQrAll()}
                  disabled={isLoadingGenAll}
                >
                  {intl.formatMessage({ id: "113248", defaultMessage: "Generate All" })}
                </button>

                <button type="button" className="btn btn-primary mr-2">
                  {intl.formatMessage({ id: "113249", defaultMessage: "Download All" })}
                </button> */}
                <Link to="/CreateDynamicQR">
                  <button type="button" className="btn btn-primary">
                    {intl.formatMessage({ id: "113002", defaultMessage: "Create" })}
                  </button>
                </Link>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <div id="qr_render"></div>
              <div className="form-row align-items-center">
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "113003", defaultMessage: "Text Search" })}
                    style={{ width: "100%" }}
                    onChange={(e) => setSearchQrDynamic(e.target.value)}
                  />

                </div>
                <div className="col-sm-2">
                  <button
                    type="button"
                    id="button"
                    className="btn btn-primary"
                    style={{ width: "100%" }}
                    onClick={() => handleSearchQrDynamic()}
                  >
                    {intl.formatMessage({ id: "113004", defaultMessage: "Search" })}
                  </button>
                </div>
              </div>

              <BootstrapTable
                keyField="id"
                data={dynamicQR}
                columns={columns3}
                wrapperClasses="table-responsive table-cm"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
              />
              <div className=" col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="mt-3">
                      {intl.formatMessage({ id: "107032", defaultMessage: "Item per page" })}
                      <select onChange={handlePageTableSizeChange} value={perPageTable}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Pagination2
                      className="mt-3 "
                      count={lastPageQR}
                      page={pageQR}
                      siblingCount={1}
                      boundaryCount={1}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageTableChange}
                    />
                  </div>
                </div>
              </div>
            </CardBody>

            <Modal
              open={openImageTable}
              disablePortal
              disableEnforceFocus
              disableAutoFocus
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <TransformWrapper
                    defaultScale={1}
                    defaultPositionX={200}
                    defaultPositionY={100}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <TransformComponent>
                          <div className="modal-body">
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: "500px",
                                maxHeight: "500px",
                              }}
                              src={imgSelectTable}
                            />
                          </div>
                        </TransformComponent>
                        <div className="modal-footer">
                          <div className="tools">
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              onClick={zoomIn}
                            >
                              +
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              onClick={zoomOut}
                            >
                              -
                            </button>
                          </div>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => handleCloseTable()}
                          >
                            Close
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </div>
              </div>
            </Modal>
          </Card>
        </TabPanel>

      </div>
    </Card>
  );

};
